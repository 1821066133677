@font-face {
  src: url(../static/fonts/OpenSans-Italic-VariableFont_wdth\,wght.ttf);
  font-family: opensans;
}

a {
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
}

/* Uncomment once finished; need to see loading bar for testing during development */
body::-webkit-scrollbar {
  display: none;
}

body,
html {
  font-family: "Roboto", sans-serif;
  text-align: center;
  height: 100vh;
  align-items: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

h1 {
  font-size: 1.875rem;
  font-weight: 300;
  margin: 60px 0 30px 0;
  color: #fff;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

.opening {
  display: flex;
  align-items: center;
  height: 100vh;
  position: relative;
  color: #000000;
  padding-left: 10%;
  padding-right: 10%;
}

.about {
  display: flex;
  align-items: center;
  height: 100vh;
  position: relative;
  color: #000000;
  padding-left: 10%;
  padding-right: 10%;
}

.loading-bar {
  position: fixed;
  top: 50%;
  width: 100%;
  height: 2px;
  background: #ffffff;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.5s;
}

.loading-bar.ended {
  transform: scaleX(0);
  transform-origin: 100% 0;
  transition: transform 1.5s ease-in-out;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.done-loading {
  max-width: 100%;
  overflow-x: hidden;
}

.point {
  position: absolute;
  top: 50%;
  left: 50%;
}

/* Change opacity to 1 to debug hover area */
.point .label {
  position: absolute;
  top: -20px;
  left: -20px;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: #00000077;
  border: 1px solid #ffffff77;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  font-weight: 100;
  font-size: 14px;
  opacity: 0;
}

.point.visible .label {
  transform: scale(1, 1);
}

.point .text {
  position: absolute;
  left: -70px;
  width: 200px;
  padding: 20px;
  border-radius: 4px;
  background: #000000b6;
  border: 1px solid #ffffff77;
  color: #ffffff;
  line-height: 1.3em;
  font-family: Trebuchet MS, Arial, sans-serif;
  font-weight: 100;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}

.point:hover .text {
  opacity: 1;
}

.container {
  position: fixed;
  width: 100%;
  display: flex;
  top: 0;
  left: 0;
}

.container section {
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/** * Overlay * -- only show for tablet and up */
@media only screen and (min-width: 40em) {
  .modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    visibility: hidden;
    backface-visibility: hidden;
    transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1),
      visibility 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  }
  .modal-overlay.active {
    opacity: 1;
    visibility: visible;
  }
}

/** * Modal */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  background-color: #fff;
  width: 600px;
  max-width: 75rem;
  min-height: 20rem;
  padding: 1rem;
  border-radius: 3px;
  opacity: 0;
  overflow-y: auto;
  visibility: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  backface-visibility: hidden;
  transform: scale(1.2);
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
}

.modal .close-modal {
  position: absolute;
  cursor: pointer;
  top: 5px;
  right: 15px;
  opacity: 0;
  backface-visibility: hidden;
  transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1),
    transform 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  transition-delay: 0.3s;
}

.modal .close-modal svg {
  width: 1.75em;
  height: 1.75em;
}

.modal .modal-content {
  opacity: 0;
  backface-visibility: hidden;
  transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  transition-delay: 0.3s;
}

.modal.active {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.modal.active .modal-content {
  opacity: 1;
}

.modal.active .close-modal {
  transform: translateY(10px);
  opacity: 1;
}

#blocker {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.center {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100vw
}

.mobile-container {
  display: flex;
}

.text-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  font-family: opensans;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 40px;
  animation: fadeIn 1.5s;
}

.welcome {
  opacity: 0;
  font-size: 30px;
  animation: fadeIn 1.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;  
  padding-bottom: 10px;
}

.about-me {
  opacity: 0;
  font-size: 20px;
  animation: fadeIn 1.5s;
  animation-fill-mode: forwards;
  animation-delay: 2.5s;
  padding: 10px;
}

.links {
  opacity: 0;
  font-size: 20px;
  animation: fadeIn 1.5s;
  animation-fill-mode: forwards;
  animation-delay: 4s;
  padding: 10px;
}

.desktop-info {
  opacity: 0;
  animation: fadeIn 1.5s;
  animation-fill-mode: forwards;
  animation-delay: 5s;
  padding: 10px;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
